.angularLayoutLabel {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left;
  padding-top: 0.8em;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  border-left: 1px solid rgba(34, 36, 38, 0.1);
  width: 50% !important;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.angularLayoutInput {
  padding: 0.35em;
  background: white;
  margin: 0;
  text-align: left;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  width: 68.75% !important;
}
.angularLayoutFields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .angularLayoutLabel,
  .angularLayoutInput {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .angularLayoutFields {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
