body > .skiptranslate {
  display: none;
}

#google_translate_element {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

@media print {
  #google_translate_element {
    display: none;
  }
}

.Toastify__toast--success {
  background-color: #428842;
}
.Toastify__toast--error {
  background-color: #C75B55;
}